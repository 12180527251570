.badge {
  &-btn {
    position: relative;
    margin: 0 $spacer * 0.5;

    .badge {
      @include font-type(type-13);
      top: -10px;
      left: 20px;
      width: 20px;
      height: 20px;
      padding-top: $spacer * 0.6;
      border-radius: 50%;
      padding-bottom: $spacer * 0.6;
      position: absolute;

      &-danger {
        background-color: $cinnabar;
      }
    }
  }
}
