button {
  &.btn {
    padding: calc(var(--spacer)) calc(var(--spacer) * 2.6);
    font-weight: 700;
    font-size: 9px;
    line-height: 1em;

    &.basic {
      color: var(--global-color-dark-purple);
    }

    &.primary {
      background-color: var(--global-color-coral);
      color: white;
    }

    &.outline {
      border: 1px solid var(--global-color-dark-purple);
      color: var(--global-color-dark-purple);
    }

    &.sm {
      font-size: 7px;
      padding: calc(var(--spacer) * 0.8) calc(var(--spacer) * 2);
    }

    &.lg {
      font-size: 11px;
      padding: calc(var(--spacer) * 1.4) calc(var(--spacer) * 3.3) calc(var(--spacer) * 1.3);
    }
  }
}
