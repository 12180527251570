.text-overflow-ellipsis {
  // In Order for this to work you parent should have fixed size and overflow hidden;
  // Usually the fix is min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
