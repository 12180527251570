.alert {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: $spacer * 1.8 $spacer * 1.6 $spacer * 1.9;
  line-height: $line-height-md;
  border: none;
  border-radius: $border-radius-sm;

  &-success {
    background-color: $green;
  }
  &-warning {
    background-color: $yellow;
  }
  &-danger {
    background-color: $blush;
  }

  .icon {
    margin-right: $spacer * 0.8;
  }
}
