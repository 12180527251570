.list-group {
  .heading-settings {
    padding: $spacer $spacer * 2.8 $spacer * 2;
  }

  & > .side-nav {
    padding-right: $spacer * 1.4;

    &_item {
      @include font-type(type-5);
      margin: $spacer * 0.5 0;
      border: none;
      border-radius: 0;
      color: $crayola;
      padding: $spacer * 0.5 $spacer * 2.8;
      background-color: transparent;

      & .nav-item-text {
        padding-top: $spacer * 0.2;
      }

      & .icon {
        margin-right: $spacer * 0.5;
      }

      &.active {
        margin-top: $spacer * 0.5;
        padding-left: $spacer * 2.6;
        color: $body-color;
        border-left: 2px solid;
      }
    }
  }
}

.outlet-body {
  padding-left: $spacer * 1.4;

  &_col {
    padding-right: $spacer * 4.7;
  }
}
