@charset "UTF-8";

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "abstracts/mixins";
@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/utilities";
@import "abstracts/button";

// 3rd party libraries
@import "vendors/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// Theme customization
// Base styles
@import "base/base";
@import "base/typography";

// Components styles
@import "components/button";
@import "components/alert";
@import "components/card";
@import "components/select";
@import "components/badge";
@import "components/dropdown-menu";
@import "components/side-nav";
@import "components/upload-file";

// Overrides styles
@import "bootstrap-overrides";

//primeng styles
@import "primeng/pcalendar";
@import "prime-ng/dynamic-dialog";

@import "global/classes";

:root {
  font-family: "Circular Std Bold", sans-serif;

  // GLOBAL COLORS
  --global-color-white: #ffffff;
  --global-color-dark-purple: #31334f;
  --global-color-light-purple: #404364;
  --global-color-coral: #f26c4b;
  --global-color-grey: #868686;
  --global-color-light-grey: #f2f2f2;
  --global-color-lightgrey: #eff1f5;
  --global-color-accent: #f4f5f9;
  --global-color-success-color: #8ec73f;
  --global-color-pending-color: #3f9ec7;
  --global-color-error-color: #b95653;
  --global-color-line-color: #dbd6d6;
  --global-color-step-icon: #dde1eb;
  --global-color-template-description: #657177;
  --global-color-toggle-border-color: #aeafc3;
  --global-color-coral-gradient-left: #f36d4d;
  --global-color-coral-gradient-right: #f89956;
  --global-color-blue-gradient-left: #4e57a6;
  --global-color-blue-gradient-right: #68c9d2;
  --global-color-pink-gradient-left: #6059a6;
  --global-color-pink-gradient-right: #ec4e80;
  --global-color-pink-line-gradient-left: #6059a6;
  --global-color-pink-line-gradient-right: #e04f84;
  --global-color-red: #ff0000;
  --global-color-greyish-blue: #495057;
  --global-color-greyish-blue-light: #6c757d;
  --global-color-cyan-blue-light: #a6d5fa;
  --global-color-cyan-blue-cream: #ced4da;
  --global-color-cream-blue-light: #e9ecef;
  --global-color-black: #000000;
  --global-color-dark-grey: #505052;

  // GLOBAL VARS
  --spacer: 0.625rem; // 10px
  --global-border-radius: 6px;
  --global-dropshadow-filter: drop-shadow(2px 4px 9px rgba(0, 0, 0, 0.25));
}
