@charset "UTF-8";

* {
  box-sizing: border-box;
}

.p-datepicker {
  padding: 0.5rem;
  background: var(--global-color-white);
  color: var(--global-color-greyish-blue);
  border: 1px solid var(--global-color-cyan-blue-cream);
  border-radius: 7px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: var(--global-color-light-grey);
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding: calc(var(--spacer) * 1.3);
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: var(--global-color-greyish-blue);
  background: var(--global-color-white);
  font-weight: 600;
  margin: 0;

  background: /* gradient can be an image */ linear-gradient(
      to right,
      var(--global-color-blue-gradient-left),
      var(--global-color-blue-gradient-right)
    )
    left bottom var(--global-color-light-grey) no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */
}

.p-datepicker .p-datepicker-header .p-datepicker-next .p-datepicker-next-icon {
  background-image: url("/assets/icons/svg/calendar-next.svg");
  width: 8px;
  height: 11px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev .p-datepicker-prev-icon {
  background-image: url("/assets/icons/svg/calendar-prev.svg");
  width: 8px;
  height: 11px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: var(--global-color-greyish-blue-light);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: var(--global-color-greyish-blue);
  border-color: transparent;
  background: var(--global-color-white);
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
  font-size: 15px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: calc(var(--spacer) * 0.8);
}

.p-datepicker table {
  font-size: 13px;
  margin: calc(var(--spacer) * 0.8) 0;
}

.p-datepicker table th {
  padding: calc(var(--spacer) * 0.8);
  text-align: center;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker table td span div {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

table.p-datepicker-calendar {
  tbody {
    td {
      height: 34px;
      width: 34px;
    }
  }
}

table.p-datepicker table td > span {
  transition: box-shadow 0.2s;
  display: flex;
  place-content: center;
  align-items: center;
}

.p-datepicker
  table
  td:not(.p-datepicker-other-month):hover
  span:not(.p-highlight)
  div:not(.first):not(.last):not(.date-between) {
  background: var(--global-color-white);
  border-radius: 50%;
  width: 64%;
}

div.p-datepicker td > span {
  margin: 0 auto;
  display: flex;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: var(--global-color-cream-blue-light);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--global-color-cyan-blue-light);
}

.p-disabled,
.p-component:disabled {
  color: var(--global-color-line-color);
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}

#input-review-date span.p-calendar {
  position: relative;
  display: flex;
}

.p-calendar .p-datepicker {
  width: 400px;
  height: 280px;
  min-width: unset !important;
  top: -280px !important;

  @media screen and (max-width: 769px) {
    left: -50% !important;
  }

  @media screen and (max-width: 992px) {
    left: -25% !important;
  }
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: var(--global-color-red);
}

.p-inputtext {
  font-family: "Circular Std Book";
  font-size: 13px;
  color: var(--global-color-dark-grey);
  background: var(--global-color-white);
  max-width: 444px;
  padding: calc(var(--spacer) * 1.3) calc(var(--spacer) * 3) calc(var(--spacer) * 1.3)
    calc(var(--spacer) * 1.5);
  border: 1px solid var(--global-color-toggle-border-color);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 7px;

  &::placeholder {
    color: var(--global-color-grey);
  }
}
