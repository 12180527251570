@mixin input-style {
  margin-bottom: $spacer * 0.8;
  padding-left: $spacer * 1.3;
  padding-right: $spacer * 1.3;
  height: $input-height;
  border: 1px solid $gray-light-2;
  border-radius: $border-radius-sm;
}

@mixin qty-button(
  $background-img-url,
  $background-size: 9px,
  $background-position: center,
  $border-radius: 1px,
  $padding: $spacer * 1.2
) {
  padding: $padding;
  background-image: url($background-img-url);
  background-repeat: no-repeat;
  border: 1px solid $gray-light-2;
  border-radius: $border-radius;
  background-size: $background-size;
  background-position: $background-position;
}

@mixin user-image-style($width, $height, $border-radius) {
  width: $width;
  height: $height;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 0 rgb(0, 0, 0, 0.4);
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin user-avatar-default-style($width, $height, $border-radius) {
  width: $width;
  height: $height;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 0 rgb(0, 0, 0, 0.4);
}

@mixin user-image-group($width: 39px, $height: 39px, $border-radius: 50%) {
  &_wrapper {
    &:not(:first-child) {
      margin-left: $spacer * -1.4;
    }
  }
  &_image {
    @include user-image-style($width, $height, $border-radius);
  }

  &_default-avatar {
    @include user-avatar-default-style($width, $height, $border-radius);
    @include font-type(default-avatar);
    margin-bottom: $spacer * 0.5;
    padding-top: $spacer * 0.2;
  }

  &_count {
    display: inline-flex;
    align-items: center;

    .icon {
      opacity: 0.3;
      margin-left: $spacer * 0.7;
      margin-right: $spacer * 0.7;
    }
  }

  &_title {
    @include font-type(type-27);
    text-transform: uppercase;
    margin-bottom: $spacer * 1.6;
  }
}

@mixin text-ellipsis($line-clamp: 3, $line-height: $line-height-xl) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  min-height: calc(#{$line-clamp} * #{$line-height});

  /* Fallback for browsers that don't support -webkit-line-clamp */
  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: $line-height;
    background: linear-gradient(
      to right,
      rgba($color: $white, $alpha: 0),
      rgba($color: $white, $alpha: 1) 80%
    );
  }

  @supports (-webkit-line-clamp: $line-clamp) {
    height: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;

    &:after {
      display: none;
    }
  }
}

@mixin square-image($img-side, $img-radius: $border-radius-sm) {
  img {
    height: $img-side;
    width: $img-side;
    border-radius: $img-radius;
    object-fit: cover;
  }
}

@mixin floating-pills {
  @include font-type(type-6);
  color: $white;
  text-align: center;
  text-transform: uppercase;
}

/**
* Return a font face import
* @param {string} $font The font name
* @param {string} $filename The file name
* @param {number} $font-weight The font weight
* @param {string} $font-style The font style
*/
@mixin font-import($font, $filename, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-family: $font;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src: url("#{$filename}.woff2") format("woff2"), url("#{$filename}.woff") format("woff");
  }
}

/**
* Return a font-type styling with responsive mapping.
* @param {string} $name The font type styling
*/
@mixin font-type($name: default) {
  $map: if(map_has_key($font-types, $name), map_get($font-types, $name), null);

  @if type-of($map) == map and map_has_key($map, default) {
    @each $respond, $style in $map {
      @if $respond == default {
        @include font-style($style);
      } @else {
        @include respond($respond) {
          @include font-style($style);
        }
      }
    }
  } @else if type-of($map) == string {
    @include font-type($map);
  } @else if type-of($map) == map {
    @include font-style($map);
  }
}

/**
* Return a font-type styling.
* @param {map|string} $map The font mapping
*/
@mixin font-style($map: ()) {
  @if type-of($map) == map {
    @each $type, $value in $map {
      @if $type == type {
        @include font-type($value);
      } @else {
        #{$type}: $value;
      }
    }
  } @else if type-of($map) == string {
    @include font-type($map);
  } @else {
    font: $map;
  }
}
