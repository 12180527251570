html,
body {
  height: 100vh;
  height: 100%;
}

.main-container {
  @include make-container($spacer * 2);

  @include media-breakpoint-up(lg) {
    @include make-container($spacer * 5.2);
  }
}

.icon {
  display: inline-block;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

.spinner-container {
  z-index: $zindex-spinner;
  opacity: 0.6;
}

.spinner {
  z-index: 99999999;
}
