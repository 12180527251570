$card-inner-border-radius: $card-border-radius;

.card {
  border: none;
  margin-bottom: $spacer * 2;

  &-header,
  &-footer,
  &-body {
    padding: $card-padding-default;

    &:first-child {
      padding-top: $card-padding-top-first;
      padding-bottom: $card-padding-bottom-first;
    }

    &:last-child {
      padding-bottom: $card-padding-default;
    }
  }

  &-content {
    padding: $spacer * 2.8 $card-padding-default $card-padding-default;

    .card {
      &-body,
      &-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &-header {
    @include font-type(type-3);
    height: 56px;
    background-color: $white;
    border-color: $bright-gray;

    &:first-child {
      @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }
  }

  &-title {
    &-small {
      @include font-type(type-7);
    }
  }

  &-footer {
    background-color: $white;
    padding-top: $card-padding-top-footer;
    padding-bottom: $card-padding-bottom-footer;

    &:last-child {
      @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
    }
  }

  &-body &-title {
    label {
      @include font-type(type-7);
    }
  }
}
